const regionalSection = [
  {
    value: "Kantor Pusat",
    labelKey: "KAPUS",
  },
  {
    value: "Jawa Tengah",
    labelKey: "JATENG",
  },
  {
    value: "Jawa Timur",
    labelKey: "JATIM",
  },
  {
    value: "Jawa Barat Dan Banten",
    labelKey: "JABAR",
  },
];

export default regionalSection;
