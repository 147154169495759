const pangkatSection = [
  {
    value: "A / I",
    labelKey: "A / I",
  },
  {
    value: "II",
    labelKey: "II",
  },
  {
    value: "III",
    labelKey: "III",
  },
  {
    value: "IV",
    labelKey: "IV",
  },
];

export default pangkatSection;
