const KAPUS = [
  {
    value: "Kantor Direksi",
    labelKey: "Kantor Direksi",
  },
  {
    value: "Pusdik Madiun",
    labelKey: "Pusdik Madiun",
  },
  {
    value: "Puslit Cepu",
    labelKey: "Puslit Cepu",
  },
  {
    value: "Other",
    labelKey: "Other",
  },
];

export default KAPUS;
