const JATENG = [
  {
    value: "Kantor Divre Jateng",
    labelKey: "Kantor Divre Jateng",
  },
  {
    value: "Kantor Dep Renbangbis Jateng",
    labelKey: "Kantor Dep Renbangbis Jateng",
  },
  {
    value: "KPH Balapulang",
    labelKey: "KPH Balapulang",
  },
  {
    value: "KPH Blora",
    labelKey: "KPH Blora",
  },
  {
    value: "KPH Banyumas Barat",
    labelKey: "KPH Banyumas Barat",
  },
  {
    value: "KPH Banyumas Timur",
    labelKey: "KPH Banyumas Timur",
  },
  {
    value: "KPH Cepu",
    labelKey: "KPH Cepu",
  },
  {
    value: "KPH Gundih",
    labelKey: "KPH Gundih",
  },
  {
    value: "KPH Kebon Harjo",
    labelKey: "KPH Kebon Harjo",
  },
  {
    value: "KPH Kedu Selatan",
    labelKey: "KPH Kedu Selatan",
  },
  {
    value: "KPH Kedu Utara",
    labelKey: "KPH Kedu Utara",
  },
  {
    value: "KPH Kendal",
    labelKey: "KPH Kendal",
  },
  {
    value: "KPH Mantingan",
    labelKey: "KPH Mantingan",
  },
  {
    value: "KPH Pati",
    labelKey: "KPH Pati",
  },
  {
    value: "KPH Pekalongan Barat",
    labelKey: "KPH Pekalongan Barat",
  },
  {
    value: "KPH Pekalongan Timur",
    labelKey: "KPH Pekalongan Timur",
  },
  {
    value: "KPH Pemalang",
    labelKey: "KPH Pemalang",
  },
  {
    value: "KPH Purwodadi",
    labelKey: "KPH Purwodadi",
  },
  {
    value: "KPH Semarang",
    labelKey: "KPH Semarang",
  },
  {
    value: "KPH Surakarta",
    labelKey: "KPH Surakarta",
  },
  {
    value: "KPH Telawa",
    labelKey: "KPH Telawa",
  },
  {
    value: "GM.KBM Komersial Hasil Hutan Kayu Jawa Tengah",
    labelKey: "GM.KBM Komersial Hasil Hutan Kayu Jawa Tengah",
  },
  {
    value: "GM.KBM Komersial Hasil Hutan Bukan Kayu Jawa Tengah",
    labelKey: "GM.KBM Komersial Hasil Hutan Bukan Kayu Jawa Tengah",
  },
  {
    value: "GM.KBM Industri Kayu dan Biomas",
    labelKey: "GM.KBM Industri Kayu dan Biomas",
  },
  {
    value: "Other",
    labelKey: "Other",
  },
];

export default JATENG;
