const JATIM = [
  {
    value: "Kantor Divre Jawa Timur",
    labelKey: "Kantor Divre Jawa Timur",
  },
  {
    value: "Kantor Dep Renbangbis Jatim, Malang + 5 SPH",
    labelKey: "Kantor Dep Renbangbis Jatim, Malang + 5 SPH",
  },
  {
    value: "KPH Banyuwangi Barat",
    labelKey: "KPH Banyuwangi Barat",
  },
  {
    value: "KPH Banyuwangi Selatan",
    labelKey: "KPH Banyuwangi Selatan",
  },
  {
    value: "KPH Banyuwangi Utara",
    labelKey: "KPH Banyuwangi Utara",
  },
  {
    value: "KPH Blitar",
    labelKey: "KPH Blitar",
  },
  {
    value: "KPH Bojonegoro",
    labelKey: "KPH Bojonegoro",
  },
  {
    value: "KPH Bondowoso",
    labelKey: "KPH Bondowoso",
  },
  {
    value: "KPH Jatirogo",
    labelKey: "KPH Jatirogo",
  },
  {
    value: "KPH Jember",
    labelKey: "KPH Jember",
  },
  {
    value: "KPH Jombang",
    labelKey: "KPH Jombang",
  },
  {
    value: "KPH Kediri",
    labelKey: "KPH Kediri",
  },
  {
    value: "KPH Lawu DS",
    labelKey: "KPH Lawu DS",
  },
  {
    value: "KPH Madiun",
    labelKey: "KPH Madiun",
  },
  {
    value: "KPH Madura",
    labelKey: "KPH Madura",
  },
  {
    value: "KPH Malang",
    labelKey: "KPH Malang",
  },
  {
    value: "KPH Mojokerto",
    labelKey: "KPH Mojokerto",
  },
  {
    value: "KPH Nganjuk",
    labelKey: "KPH Nganjuk",
  },
  {
    value: "KPH Ngawi",
    labelKey: "KPH Ngawi",
  },
  {
    value: "KPH Padangan",
    labelKey: "KPH Padangan",
  },
  {
    value: "KPH Pasuruan",
    labelKey: "KPH Pasuruan",
  },
  {
    value: "KPH Parengan",
    labelKey: "KPH Parengan",
  },
  {
    value: "KPH Probolinggo",
    labelKey: "KPH Probolinggo",
  },
  {
    value: "KPH Saradan",
    labelKey: "KPH Saradan",
  },
  {
    value: "KPH Tuban",
    labelKey: "KPH Tuban",
  },
  {
    value: "GM.KBM Komersial Hasil Hutan Kayu Jawa Timur",
    labelKey: "GM.KBM Komersial Hasil Hutan Kayu Jawa Timur",
  },
  {
    value: "GM.KBM Komersial Hasil Hutan Bukan Kayu Jawa Timur",
    labelKey: "GM.KBM Komersial Hasil Hutan Bukan Kayu Jawa Timur",
  },
  {
    value: "GM.KBM Komersial Ekowosata Jawa Timur",
    labelKey: "GM.KBM Komersial Ekowosata Jawa Timur",
  },
  {
    value: "Other",
    labelKey: "Other",
  },
];

export default JATIM;
